<template>
  <div class="wrap">
    <div class="The_header">
      <ul class="bg-fff">
        <li class="left">
          <router-link to>
            <i class="iconfont icon-fanhui" @click="$router.back(-1)"></i>
          </router-link>
        </li>
        <li>{{ t("common.title.grabRecords") }}</li>
      </ul>
    </div>
    <BetterScroll
      ref="scrollRef"
      @pullingUp="fetchMorePage"
      v-if="alreadyReq"
      class="BetterScroll_1"
    >
      <div class="main main_page">
        <div class="Order_list">
          <div class="Order_list_box">
            <div class="no_data" v-if="!listItems || listItems.length == 0">

              <p>{{ t("common.message.noRecords") }}</p>
            </div>
            <div
              class="bata"
              :key="index"
              v-for="(item,index) in listItems"
            >
              <ul>
                <li>
                  {{ t("profile.label.time") }}:
                  <b>{{ formatServerDateTime(item.createDate) }}</b>
                </li>
                <li>
                  {{ t("profile.label.serialNumber") }}:
                  <b>{{ item.orderNo }}</b>
                </li>
              </ul>
              <ol>
                <h2><img :src="sysDataDictionaryWbObj.TXHQDZ?.dataContent + item.img" alt="" /></h2>
                <h3>{{ item.productSubsetName }}</h3>
                <li>
                  <span>{{ formatMoney(item.productSubsetAmount) }}</span>
                  <span class="r">x{{ item.orderCnt }}</span>
                </li>
              </ol>
              <ul>
                <li>
                  {{ t("profile.label.orderAmount") }}
                  <span>{{ formatMoney(item.orderAmount) }}</span>
                </li>
                <li>
                  {{ t("profile.label.commission") }}
                  <span>{{ formatMoney(item.profit) }}</span>
                </li>
                <li>
                  {{ t("profile.label.return") }}
                  <span class="c-red">
                    {{ formatMoney(item.orderAmount+item.profit) }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { ref,reactive,onMounted,watch } from "vue";
import BetterScroll from "@/components/BetterScroll.vue";
import api from "@/api";
import { sysDataDictionaryWbObj } from '@/hooks/sysDataDictionaryWbObj'
import {
  formatMoney,
  formatMoneySign,
  formatServerDateTime,
  formatPercentage,
  formatShortDateTime,
} from "@/utils/formatter";
import { useI18n } from "@/lang";
export default {
  components: { BetterScroll },
  setup() {
    const { t } = useI18n();
     const scrollRef = ref(null);
    const isStopReq = ref(false);
    const listItems = reactive([]);
    const currentPage = ref(0);
    const alreadyReq = ref(false);
    const fetchMorePage = () => {
      if(isStopReq.value){
        return
      }
      api
        .orderList({
          current: currentPage.value + 1,
          size: 10,
        })
        .then((res) => {
          if (res?.data?.success) {
            alreadyReq.value = true;
            if (+res.data.data?.pages <= +currentPage.value+1) {
              isStopReq.value = true;
            }
            currentPage.value += 1;
            listItems.push(...res.data.data?.records);
          }
        });
    };

    onMounted(() => {
      fetchMorePage();
    });

    watch(listItems, () => {
      if (scrollRef.value) {
        scrollRef.value.refresh();
      }
    });

    return {
      t,
      scrollRef,
      formatMoney,
      formatMoneySign,
      formatPercentage,
      formatServerDateTime,
      formatShortDateTime,
      listItems,
      fetchMorePage,
      alreadyReq,
      sysDataDictionaryWbObj
    };
  },
};
</script>
<style lang="scss" scoped>
.wrap .main {
  position: relative;
  top: 0;
}
</style>
